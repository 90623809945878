<style lang="scss" scoped>
::v-deep {
  .card {
    padding: 0;
  }
}
.view-pre-orders {
  cursor: pointer;
}
.cart-items {
  overflow: auto;
  max-height: 77vh;
}
::v-deep .ant-drawer-content-wrapper {
  width: 315px;
}
::v-deep .ant-drawer-body {
  padding: 0px;
}
.order-history-padding {
  margin-top: 93px !important;
}
.cart-count {
  color: $medium-gray;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
}
.conditional-cart-statement {
  color: $medium-gray;
  font-weight: normal;
  font-size: 14px;
  padding: 0px 15px;
}
.empty-cart {
  font-size: 16px;
  color: $orange;
  margin-left: auto;
  padding-top: 25px;
}
.empty-label {
  cursor: pointer;
}
.total {
  padding: 20px;
  background: #ebeef5;
}

.tax-label {
  font-size: 12px;
  color: grey;
}

.sidebar-btn {
  width: 100%;
  margin-top: 20px;
}
.header {
  display: flex;
  margin-top: 35px;
  padding: 0px 15px 10px;
  border-bottom: 1px solid lightgrey;
}
.grandtotal {
  justify-content: space-between;
  display: flex;
  width: 100%;
}
</style>
<template>
  <div id="drawer">
    <div class="drawer-container">
      <a-drawer
        width="350"
        placement="right"
        :destroy-on-close="true"
        :visible="visible"
        :mask-closable="false"
        :mask="false"
        :closable="true"
        :push="true"
        @close="onClose"
      >
        <div id="">
          <BaseCard :class="{ 'order-history-padding': selectedOrderHistory }" class="shadow-none">
            <div class="cart">
              <div class="header">
                <div class="cart-title">
                  <h2 class="m-bottom-0">Cart</h2>
                  <div v-if="hasPreOrders" class="view-pre-orders font-600 primary-color" @click="goToPreOrder()">
                    View my pre-orders
                  </div>
                  <p class="cart-count">
                    {{ $t("navCart.cartCount", { numberOfItems: totalItems }) }}
                  </p>
                  <h3 v-if="getCartItems.length < 1" class="conditional-cart-statement">
                    {{ $t("navCart.noItems") }}
                  </h3>
                </div>
                <div v-if="getCartItems.length > 0" class="empty-cart">
                  <label class="empty-label" :style="{ color: contentColor }" @click="handleEmptyCart">
                    Empty cart
                  </label>
                </div>
              </div>
              <div class="cart-items">
                <div v-for="item in getCartItems" :key="item.id" class="">
                  <CartItem :content-color="contentColor" :line-item="item" />
                </div>
              </div>
            </div>
          </BaseCard>
          <p class="step-summary" />
        </div>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            zIndex: 1,
          }"
        >
          <div class="total">
            <div class="grandtotal">
              <h3 class="label">
                SubTotal <br />
                <label class="tax-label">All taxes included</label>
              </h3>
              <h3 class="grandtotal-value">$ {{ grandTotal }}</h3>
            </div>
            <BaseButton
              v-if="getCartItems.length > 0"
              :style="{ background: contentColor }"
              class="sidebar-btn"
              type="cta"
              @click="handleClickCheckout"
            >
              Proceed to checkout
            </BaseButton>
          </div>
        </div>
      </a-drawer>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import BaseCard from "@/components/common/BaseCard.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import CartItem from "@/components/marketplace/common/CartItem.vue";
import { setCallbackConfigForPatient } from "@/utils/auth";

export default {
  name: "MarketplaceCartDrawer",
  components: {
    BaseCard,
    BaseButton,
    CartItem,
  },
  data() {
    return {
      drawer: true,
      visible: true,
      selectedOrderHistory: false,
      // isPatient: '',
      userType: "",
      contentColor: "",
      hasPreOrders: false,
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentUser",
      "getEmptyCart",
      "getSelectedCurrentLocationId",
      "getCartItems",
      "getCurrentPurchase",
      "getSelectedShippingRates",
      "getOrgId",
    ]),
    ...mapGetters("usersModule", ["isLoggedIn"]),
    subtotalForAllProducts() {
      let subTotal = 0;
      this.getCartItems.forEach((product) => {
        subTotal += product.price * product.qty;
      });
      return subTotal.toFixed(2);
    },
    shippingAmount() {
      let total = 0;
      this.getSelectedShippingRates.forEach((selectedRate) => {
        total += selectedRate.rate;
      });
      if (total > 0) {
        return total.toFixed(2);
      }
      return (0).toFixed(2);
    },
    totalItems() {
      let items = 0;
      this.getCartItems.forEach((item) => {
        items += item.qty;
      });
      return items;
    },
    grandTotal() {
      if (!this.getEmptyCart) {
        if (this.getCurrentPurchase.appliedRebate) {
          // eslint-disable-next-line max-len
          return (
            Number(this.subtotalForAllProducts) +
            Number(this.shippingAmount) -
            this.getCurrentPurchase.appliedRebate
          ).toFixed(2);
        }
        return (Number(this.subtotalForAllProducts) + Number(this.shippingAmount)).toFixed(2);
      }
      return "0.00";
    },
  },
  async mounted() {
    this.contentColor = localStorage.contentColor ? localStorage.contentColor : "#f05a28";
    this.userType = localStorage.userType === "practiceUser" ? localStorage.userType : "orgUser";
    if (this.$route.name !== "PreOrdersCart") {
      this.hasPreOrders = await this.checkLocationPreOrders({
        orgId: this.getOrgId,
        locationId: this.getSelectedCurrentLocationId,
      });
    }
  },
  methods: {
    ...mapActions({
      createPurchase: "createPurchase",
      addShippingRatesToPurchase: "addShippingRatesToPurchase",
      fetchgetCartItems: "fetchgetCartItems",
      emptyActiveCart: "emptyActiveCart",
      commitEmptyCart: "commitEmptyCart",
      setLoginDetails: "setLoginDetails",
      addLocalProductsToCart: "marketplaceProduct/addLocalProductsToCart",
    }),
    ...mapActions("ordersModule", ["checkLocationPreOrders"]),
    async handleEmptyCart() {
      if (this.userType === "practiceUser" && !this.isLoggedIn) {
        VueCookies.remove("productArrayString");
        this.commitEmptyCart();
      } else {
        await this.emptyActiveCart(this.getSelectedCurrentLocationId);
        this.$root.$emit("deleteProduct", false);
      }
    },
    async handleClickCheckout() {
      // this.$router.push({ name: 'PatientProfileInfo' }).catch(() => {});
      if (this.userType === "practiceUser" && !this.isLoggedIn) {
        setCallbackConfigForPatient();
      } else {
        this.$router
          .push({ name: "NewCheckout", params: { locationId: this.getSelectedCurrentLocationId } })
          .catch(() => {});
        this.visible = false;
        this.$emit("toggleDrawer", false);
      }
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.$emit("toggleDrawer", false);
    },
    goToPreOrder() {
      if (this.$route.name === "PreOrdersCart") return;
      this.onClose();
      this.$router.push({
        name: "PreOrdersCart",
        params: {
          locationId: this.getSelectedCurrentLocationId,
        },
      });
    },
  },
};
</script>
