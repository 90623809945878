<template>
  <div class="breadcrumbs">
    <div v-for="c in active" :key="c.slug" class="crumb">
      <div :class="{ active: c.active }">
        {{ c.name }}
      </div>
      <span v-if="crumbs.length > 1" class="separator">/</span>
    </div>
    <div v-if="crumbs.length > 1" class="crumb">
      <span>
        {{ crumbs[crumbs.length - 1].name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketplaceBreadcrumbs",
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    active() {
      return this.crumbs.filter((c) => {
        return c.active;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  color: $simpatra-blue;
  display: flex;
  flex-wrap: wrap;
  font-size: 1em;
}
.crumb {
  display: flex;
  margin: 5px 0px;
}
.active {
  color: $simpatra-blue;
  font-weight: 600;
  text-decoration: none;
}
.separator {
  margin: 0 10px 0px 5px;
  color: $dark-grey;
}
@media screen and (max-width: 920px) {
  .breadcrumbs {
    font-size: 0.8em;
  }
}
</style>
