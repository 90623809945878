<template>
  <div id="line-item">
    <div>
      <div v-if="!hover" class="line-item" @mouseover="hover = true" @mouseleave="hover = false">
        <p class="name">
          {{ lineItem.productName }}
        </p>
        <p class="subtotal">$ {{ subTotal.toFixed(2) }}</p>
      </div>
      <el-tooltip v-if="hover" class="item" effect="dark" :content="lineItem.productName" placement="top">
        <div class="item-details" @mouseover="hover = true" @mouseleave="hover = false">
          <i :style="{ color: contentColor }" class="qty-update el-icon-remove" @click="removeOne" />
          <BaseNumberInput v-model.number="qty" class="input" />
          <i :style="{ color: contentColor }" class="qty-update el-icon-circle-plus" @click="addOne" />
          <i :style="{ color: contentColor }" class="remove-product el-icon-delete top" @click="removeProduct" />
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import VueCookies from "vue-cookies";
import { mapActions, mapGetters } from "vuex";
import BaseNumberInput from "@/components/common/BaseNumberInput.vue";

export default {
  name: "CartItem",
  components: {
    BaseNumberInput,
  },
  props: {
    lineItem: {
      type: Object,
      default() {
        return {};
      },
    },
    qtyDisable: {
      type: Boolean,
    },
    contentColor: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      totalAmount: 0,
      hover: false,
      cartProducts: [],
      userType: "",
    };
  },
  computed: {
    ...mapGetters({
      getCartItems: "getCartItems",
      getProduct: "marketplaceProduct/getProduct",
      getCurrentUser: "getCurrentUser",
    }),
    ...mapGetters("locationsModule", ["getCurrentLocationSelected"]),
    ...mapGetters("usersModule", ["isLoggedIn"]),
    subTotal() {
      if (this.lineItem.individual === true) {
        return this.lineItem.price * this.lineItem.packageSize * this.lineItem.qty;
      }
      return this.lineItem.price * this.lineItem.qty;
    },
    qty: {
      get() {
        return this.lineItem.qty;
      },
      set(qty) {
        if (qty <= 0) {
          this.qty = 1;
          return;
        }
        if (qty) {
          this.updateQty(qty);
        }
      },
    },
  },
  created() {
    this.userType = localStorage.userType === "practiceUser" ? localStorage.userType : "orgUser";
  },
  methods: {
    ...mapActions({
      updateItemQty: "updateItemQty",
      removeItemFromCart: "removeItemFromCart",
      fetchProductById: "marketplaceProduct/fetchProductById",
      commitItemToCart: "marketplaceProduct/commitItemToCart",
      commitEmptyCart: "commitEmptyCart",
    }),
    async updateQty(newVal) {
      await this.fetchProductById(this.lineItem.productId);
      if (
        newVal >= this.getProduct.minQtyForPurchase &&
        (this.getProduct.limitedQty >= newVal || !this.getProduct.limited)
      ) {
        this.updateItemQty({
          locationId: this.getCurrentLocationSelected,
          productId: this.lineItem.id,
          qty: newVal,
        });
      }
      if (newVal < this.getProduct.minQtyForPurchase) {
        Message({
          type: "warning",
          showClose: true,
          message: `The product ${this.lineItem.productName} has a minimum order quantity of ${this.getProduct.minQtyForPurchase}`,
        });
      }
      if (this.getProduct.limitedQty < newVal && this.getProduct.limited) {
        Message({
          type: "warning",
          showClose: true,
          message: `The product ${this.lineItem.productName} has ${this.getProduct.limitedQty} left in stock`,
        });
      }
    },
    removeOne() {
      if (this.qty >= 2) {
        this.qty -= 1;
      }
    },
    addOne() {
      this.qty += 1;
    },
    async removeProduct() {
      if (this.userType === "practiceUser" && !this.isLoggedIn) {
        const productArrayString = VueCookies.get("productArrayString");
        this.cartProducts = JSON.parse(productArrayString);

        // remove selected product from cookies
        this.cartProducts = this.cartProducts.filter((product) => {
          return product.productId !== this.lineItem.id;
        });

        VueCookies.set("productArrayString", JSON.stringify(this.cartProducts));
        await this.commitItemToCart(this.cartProducts);
      } else {
        await this.removeItemFromCart({
          locationId: this.getCurrentLocationSelected,
          productId: this.lineItem.id,
        });
        this.$root.$emit("deleteProduct", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  margin-top: 12px;
  height: 30px;
}
.qty-update {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f05a28;
  opacity: 5;
  transition: all 0.2s ease;
  margin: 0px 5px;
}
.remove-product {
  color: #f05a28;
  font-size: 20px;
  margin-left: 15px;
  padding: 20px;
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
}
.item-details {
  display: flex;
  padding-left: 20px;
  background-color: #f5f7fa;
  cursor: pointer;
}
.line-item {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  padding: 10px 10px;
  .name {
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
}
</style>
