<template>
  <div class="">
    <div
      class="header"
    >
      <div class="title">
        <h3>{{ title }}</h3>
        <div class="el-icon">
          <img
            v-show="title === 'Pharmaceuticals' && !gridIcon"
            src="@/assets/grid-grey.svg"
            class="grid-icon el-icon-menu"
            @click="routeGridList('grid')"
          >
          <img
            v-show="title === 'Pharmaceuticals' && gridIcon"
            src="@/assets/grid-active.svg"
            class="grid-icon el-icon-menu"
            @click="routeGridList('grid')"
          >
          <img
            v-show="title === 'Pharmaceuticals' && !listIcon"
            src="@/assets/list-grey.svg"
            class="list-icon el-icon-s-fold"
            @click.prevent="routeGridList('list')"
          >
          <img
            v-show="title === 'Pharmaceuticals' && listIcon && !gridIcon"
            src="@/assets/list-active.svg"
            class="list-icon el-icon-s-fold"
            @click.prevent="routeGridList('list')"
          >
        </div>
      </div>

      <div
        v-if="!allMedicalEquipments && !allBestSelling && !allPharamaProducts
          && title === 'Pharmaceuticals' && getResults.length > 0"
        class="view-all"
      >
        <label
          class="view-list"
          @click="viewAllProducts('pharma-products')"
        >View All</label>
      </div>

      <div
        v-if="!allMedicalEquipments && !allBestSelling && !allPharamaProducts
          && title === 'Medical Equipment' && getResults.length > 0"
        class="view-all"
      >
        <label
          class="view-list"
          @click="viewAllProducts('medical-equipment')"
        >View All</label>
      </div>

      <span
        v-if="allBestSelling || allMedicalEquipments || allPharamaProducts"
        class="viewing"
      >
        Viewing {{ getResults.length }} out of
        <span class="weighted">
          {{ getAllResults.length }}
          results
        </span>
      </span>
      <BaseButton
        v-if="allBestSelling || allMedicalEquipments || allPharamaProducts"
        icon="el-icon-back"
        class="back-button"
        @click="backToHomePage"
      >
        Back
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/common/BaseButton.vue';

export default {
  name: 'MarketplaceHeader',
  components: {
    BaseButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    getResults: {
      type: Array,
      required: true,
    },
    getAllResults: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gridIcon: true,
      listIcon: false,
      allBestSelling: false,
      allMedicalEquipments: false,
      allPharamaProducts: false,
      count: 0,
      flag: true,
    };
  },
  created() {
    // alert('......................');
  },
  mounted() {
    // this.$root.$on('displayList', (result) => {
    //   if (this.flag) {
    //     this.routeGridList('list');
    //     this.flag = false;
    //   }


    // });
  },
  methods: {
    backToHomePage() {
      this.allBestSelling = false;
      this.allMedicalEquipments = false;
      this.allPharamaProducts = false;
      this.$emit('vendorProductCategory', '');
    },
    viewAllProducts(type) {
      if (type === 'medical-equipment') {
        this.allBestSelling = false;
        this.allMedicalEquipments = true;
        this.allPharamaProducts = false;
        this.$emit('vendorProductCategory', 'medical-equipment');
      }

      if (type === 'pharma-products') {
        this.allBestSelling = false;
        this.allMedicalEquipments = false;
        this.allPharamaProducts = true;
        this.$emit('vendorProductCategory', 'pharma-products');
      }
    },
    routeGridList(key) {
      if (key === 'grid') {
        this.gridIcon = true;
        this.listIcon = false;
        this.$root.$emit('displayGridView', true);


        // this.$emit('displayView', 'grid');
      }
      if (key === 'list') {
        this.listIcon = true;
        this.gridIcon = false;
        // this.routeGridList('list');
        if (this.flag) {
          this.$root.$emit('displayListView', true);
        }

        // this.routeGridList('list');
        // this.count = this.count + 1;


        // this.$emit('displayView', 'list');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-icon {
  padding-left: 5px;
  border-right: 1px solid lightgrey;
  cursor: pointer;
}
.list-icon {
  cursor: pointer;
}
.viewing {
  padding-top: 20px;
  margin-left: auto;
}
.back-button {
  padding-top: 15px;
}
.title {
    padding-top: 20px;
    display: flex;
}
.header {
  display: flex;
  padding: 20px 0px;
}
.view-all {
  padding-top: 28px;
  padding-right: 5px;
  margin-left: auto;
}
.view-list {
  color: $orange;
  cursor: pointer;
}
</style>
